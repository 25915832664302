/* eslint-disable max-len */
import React from "react";
import Link from "@Components/Link";
import styles from "./NewsBrand2.module.scss";

const MENU_ITEMS = [
   {
      title: "VNExpress",
      iconDesktop: "/assets/img/vnexpress-desktop.png",
      iconMobile: "/assets/img/vnexpress-mobile.png",
      path: "https://vnexpress.net/space-t-cung-cap-da-dang-dich-vu-trong-linh-vuc-noi-that-4810707.html",
   },
   {
      title: "CafeF",
      iconDesktop: "/assets/img/cafef-desktop.png",
      iconMobile: "/assets/img/cafef-mobile.png",
      path: "https://cafef.vn/noi-that-space-t-mua-noi-that-online-va-ket-noi-nha-thau-188241031160940413.chn",
   },
   {
      title: "24h",
      iconDesktop: "/assets/img/24h-desktop.png",
      iconMobile: "/assets/img/24h-mobile.png",
      path: "https://www.24h.com.vn/doanh-nghiep/noi-that-space-t-lan-gio-moi-trong-xu-huong-mua-noi-that-online-va-ket-noi-doi-tac-thi-cong-c849a1614470.html",
   },
];

interface MenuItem {
   title: string;
   iconDesktop: string;
   iconMobile: string;
   path: string;
}

type Props = {
   stylesHornorBrand?: React.CSSProperties;
};

const NewsBrand2: React.FC<Props> = ({ stylesHornorBrand }) => {
   const menus: MenuItem[] = MENU_ITEMS;

   return (
      <div style={stylesHornorBrand ? stylesHornorBrand : {}} className={styles["hornor-brand"]}>
         <div className={styles["container"]}>
            <h2 className={styles["section-title"]}>Space T có mặt trên tin tức của</h2>
            <div className="row gx-2 gy-3 gx-md-3">
               {menus.map(({ title, iconMobile, iconDesktop, path }, index) => (
                  <div className="col-4" key={path || index}>
                     <Link target="_blank" rel="noopener noreferrer" href={path || "#"}>
                        <picture>
                           <source media="(min-width: 768px)" srcSet={iconDesktop} />
                           <source media="(max-width: 767px)" srcSet={iconMobile} />
                           <img title={title} alt={title} src={iconMobile} />
                        </picture>
                     </Link>
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
};

export default NewsBrand2;
